import React, { Component } from "react";
import {Container} from "react-bootstrap";
import AOS from 'aos';
import 'aos/dist/aos.css';
import HeroForm from "../component/Form";
import Modal from "react-bootstrap/Modal";

import PhoneIcon from './../assets/img/phone-icon.png';
import EmailIcon from './../assets/img/email.png';

import Family from './../assets/img/family3.jpg';
import CheckGuardIcon from './../assets/img/guard-icon.png';


export default class GetEstimate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: false,
            thank_you: false,
        };
    }
    loaderShow() {
        window.scrollTo(0, 0);
        this.setState({loader: true});
        setTimeout(() => {
            this.setState({loader: false});
        }, 1500);
    };
    componentDidMount() {
        this.loaderShow();
        AOS.init({
            once: true,
        });
    }
    handleSubmit = (event) => {
        event.preventDefault();
        this.setState({ thank_you: true });
    };
    thank_you_modal_close = () => {
        this.setState({ thank_you: false });
    };
    render() {
        return (
            <main>
                {
                    this.state.loader === true ? <div className={'loader'}><div className={'bar'}><span></span></div></div> : ''
                }
                <HeroForm />
                <section className={'form-wrapper'}>
                    <Container>
                        <div className="row">
                            <div className="col-lg-10 offset-lg-1">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="img-wrapper" data-aos="fade-up">
                                            <h1 className={'counter'}><img src={CheckGuardIcon} alt="" className={'icon'}/>100<span>%</span></h1>
                                            <img src={Family} alt="" />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="card card-form" data-aos="fade-left">
                                            <div className="card-body">
                                                <form action="#" onSubmit={this.handleSubmit}>
                                                    <div className="form-group">
                                                        <label className={'form-label'}>Your Full Name *</label>
                                                        <input type="text" className="form-control" placeholder={'Enter your full name'}/>
                                                    </div>
                                                    <div className="form-group">
                                                        <label className={'form-label'}>Moving From Zip Code *</label>
                                                        <input type="text" className="form-control" placeholder={'Enter zip code here'}/>
                                                    </div>
                                                    <div className="form-group">
                                                        <label className={'form-label'}>Moving To Zip Code *</label>
                                                        <input type="text" className="form-control" placeholder={'Enter zip code here'}/>
                                                    </div>
                                                    <div className="form-group">
                                                        <label className={'form-label'}>Phone *</label>
                                                        <input type="text" className="form-control" placeholder={'Enter Phone Number'}/>
                                                    </div>
                                                    <div className="form-group">
                                                        <label className={'form-label'}>Your Email *</label>
                                                        <input type="email" className="form-control" placeholder={'Enter Your Email'}/>
                                                    </div>
                                                    <div className="form-group">
                                                        <label className={'form-label'}>General Comments *</label>
                                                        <input type="text" className="form-control" placeholder={'Enter Text here'}/>
                                                    </div>
                                                    <button className={'btn btn-primary btn-lg btn-submit'}>Get Your Quote Now</button>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Container>
                </section>
                <Modal show={this.state.thank_you} onHide={this.thank_you_modal_close} className={'thank_you_modal'} centered>
                    <Modal.Body>
                        <Modal.Title>Thank you!</Modal.Title>
                        <p>One of our representatives will contact you shortly.</p>
                        <button onClick={this.thank_you_modal_close}>Ok</button>
                    </Modal.Body>
                </Modal>
            </main>
        );
    }
}