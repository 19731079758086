import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class PageNotFound extends Component {

    render() {
        return (
            <main>
                <div className="text-center py-5 my-5">
                    <h1 className={'display-1 fw-bolder text-center'}>Oops!</h1>
                    <h1 className={'text-center mb-4'}>404 - Page Not Found</h1>
                    <p className={'mb-5'}>The page you are looking for might have been removed <br/> had its name changed or is temporarily unavailable.</p>
                    <Link to={'/'} className={'btn btn-primary btn-lg text-uppercase'}>Go To Home</Link>
                </div>
            </main>
        );
    }
}