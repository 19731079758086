import React, { Component, props } from "react";
import { Link, NavLink } from "react-router-dom";
import {Button, Container, Nav, Navbar, NavDropdown} from "react-bootstrap";
import './../assets/scss/header.scss';
import $ from "jquery";

// Images ==================
import Logo from './../assets/img/logo.png';
import Phone from './../assets/img/phone.png';
import Home from './../assets/img/home.png';
import Menu from './../assets/img/menu.png';
import Close from './../assets/img/close.png';
// import Plan from './../assets/img/plan.svg';
// import PlanYellow from './../assets/img/plan-yellow.svg';
// import User from './../assets/img/user.svg';

export default class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            navbarExpanded: false
        };
        this.toggleOpen = this.toggleOpen.bind(this);
        this.toggleClose = this.toggleClose.bind(this);
    }
    toggleOpen() {
        window.scrollTo(0, 0);
        this.setState({ navbarExpanded: !this.state.navbarExpanded });
    }
    toggleClose() {
        this.setState({ navbarExpanded: false });
    }

    componentDidMount() {
        $(document).on('click', (event) => {
            if ($(event.target).closest('.navbar-toggler').length) {

            } else {
                if ($(".navbar-collapse").hasClass("show")) {
                    this.toggleClose();
                    $(".navbar-toggler").click()
                }
            }
        });
    }
    render() {
        return (
            <Navbar bg="light" expand="lg" >
                <Container>
                    <div className="top-area">
                        <Link className={'navbar-brand'} to="/">
                            <img src={Logo} alt="Prestige" />
                        </Link>
                        <a href={'tel:6784628392'} className={'btn btn-phone ml-auto'}>
                            <img src={Phone} alt="" className={'icon'}/>
                            <span>
                                <span>Call Now to Get Started</span>
                                <span className={'highlight'}>678-462-8392</span>
                            </span>
                        </a>
                    </div>
                </Container>
                <div className="mobile-action-navbar">
                    <Container>
                        <Navbar.Toggle aria-controls="navbarScroll" onClick={this.toggleOpen}>
                            {
                                this.state.navbarExpanded === true
                                    ? <img src={Close} alt="" className="icon close" />
                                    : <img src={Menu} alt="" className="icon" />
                            }
                        </Navbar.Toggle>
                        <Link to={'/'} className={'home-icon'}>
                            <img src={Home} alt=""/>
                        </Link>
                    </Container>
                </div>
                <Navbar.Collapse className={'navbar-area'} id="navbarArea">
                    <Container>
                        <Nav className="menu" navbarScroll>
                            <NavLink to={'/'} className={'home-icon'}>
                                <img src={Home} alt=""/>
                            </NavLink>
                            <NavLink to="/about-us" className='is-active' onClick={this.toggleOpen}>About Us</NavLink>
                            <NavLink to="/long-distance-moves" className='is-active'>Long Distance Moves</NavLink>
                            <NavLink to="/storage-service" className='is-active'>Storage Service</NavLink>
                            <NavLink to="/get-estimate" className='is-active'>Get FREE Estimate</NavLink>
                            <NavLink to="/contact-us" className='is-active'>Contact Us</NavLink>
                        </Nav>
                    </Container>
                </Navbar.Collapse>
            </Navbar>
        );
    }

}
