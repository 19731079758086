import React, { Component } from "react";
import {Container} from "react-bootstrap";
import AOS from 'aos';
import 'aos/dist/aos.css';
import HeroForm from "../component/Form";

import LeftSideImage from './../assets/img/Long-Distance-Moves.jpg';
import CTAImg from "../assets/img/fmcsa.jpg";


export default class LongDistanceMoves extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: false,
        };
    }
    loaderShow() {
        window.scrollTo(0, 0);
        this.setState({loader: true});
        setTimeout(() => {
            this.setState({loader: false});
        }, 1500);
    };
    componentDidMount() {
        this.loaderShow();
        AOS.init({
            once: true,
        });
    }
    render() {
        return (
            <main>
                {
                    this.state.loader === true ? <div className={'loader'}><div className={'bar'}><span></span></div></div> : ''
                }
                <HeroForm />
                <section className={'horizontal-card-v2 pb-0'}>
                    <div className="left-img">
                        <img src={LeftSideImage} alt="" data-aos="fade-right"/>
                    </div>
                    <div className="content" data-aos="fade-left">
                        <h1 className={'title'}>Long Distance Moves</h1>
                        <p>Our company offers long distance relocation services for residential and business operations. Long distance moves typically refer to interstates moves which involve crossing state lines. We do not charge shipments by weight; upon receiving a specific list of items your designated relocation specialist will be able to determine your binding and guaranteed transportation cost.</p>
                        <p>This fee is known as a Flat, Binding Cost which does not change on the move date, as long as your list of items requested for service remains what was initially agreed upon on the order for service. As a consumer you retain the right to add or subtract and items in the days leading up to the date designated for the move. As such, your transportation cost will be revised and adjusted accordingly.</p>
                        <p>You may utilize our packing services in addition to our inclusive amenities. Allowing our professionals to pack and upload your items ensures their protection and their safety in transit. Our proficient staff has the expertise and the experience to make sure that clients that choose our packing services find their belongings properly packed and organized.</p>
                    </div>
                </section>
                <section className={'cta_2 p-5'}>
                    <div className="text-center">
                        <a href="#get-your-quote-now" className={'btn btn-primary btn-xl'}>Get Your Quote Now</a>
                    </div>
                </section>
                <section className={'cta'}>
                    <Container>
                        <div className="card">
                            <div className="row align-items-lg-center">
                                <div className="col-lg-7">
                                    <div className="content" data-aos="fade-right">
                                        <h1 className="title">Our transportation costs <br/> include the following:</h1>
                                        <ul className={'check-list'}>
                                            <li>Free tolls, mileage and taxes</li>
                                            <li>Free special moving blankets to wrap and protect your fragile items</li>
                                            <li>Free assembling and disassembling of all furniture</li>
                                            <li>Free detailed inventory list on move date</li>
                                            <li>Free standard valuation protection at the rate of .60/lb</li>
                                            <li>Free one month storage if required</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-5">
                                    <img src={CTAImg} alt="" className={'img-fluid'} data-aos="fade-up"/>
                                </div>
                            </div>
                        </div>
                    </Container>
                </section>
            </main>
        );
    }
}