import React, { Component, props } from "react";
import { Link, NavLink } from "react-router-dom";
import {Button, Container, Nav, Navbar, NavDropdown} from "react-bootstrap";
import './../assets/scss/header.scss';
import Modal from "react-bootstrap/Modal";

import Slider_img_1 from './../assets/img/banner_1.jpg';
import Slider_img_2 from './../assets/img/banner_2.jpg';
import Slider_img_3 from './../assets/img/banner_3.jpg';
import Slider_img_4 from './../assets/img/banner_4.jpg';
import Slider_img_5 from './../assets/img/banner_5.jpg';
import Slider_img_6 from './../assets/img/banner_6.jpg';
import Slider_img_7 from './../assets/img/banner_7.jpg';
import Slider_img_8 from './../assets/img/banner_8.jpg';
import Slider_img_9 from './../assets/img/banner_9.jpg';
import Slider_img_10 from './../assets/img/banner_10.jpg';
import Slider_img_11 from './../assets/img/banner_11.jpg';
import Slider from "react-slick";

var settings = {
    infinite: true,
    arrows: false,
    dots: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    cssEase: 'ease-in-out',
    fade: true,
};

export default class HeroForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            thank_you: false,
        };
    }
    handleSubmit = (event) => {
        event.preventDefault();
        this.setState({ thank_you: true });
    };
    thank_you_modal_close = () => {
        this.setState({ thank_you: false });
    };
    render() {
        return (
            <div>
                <section id={'get-your-quote-now'} className={'hero'}>
                    <Slider {...settings} className={'hero_slider'}>
                        <img src={Slider_img_1} alt="" className={'bg'}/>
                        <img src={Slider_img_2} alt="" className={'bg'}/>
                        <img src={Slider_img_3} alt="" className={'bg'}/>
                        <img src={Slider_img_4} alt="" className={'bg'}/>
                        <img src={Slider_img_5} alt="" className={'bg'}/>
                        <img src={Slider_img_6} alt="" className={'bg'}/>
                        <img src={Slider_img_7} alt="" className={'bg'}/>
                        <img src={Slider_img_8} alt="" className={'bg'}/>
                        <img src={Slider_img_9} alt="" className={'bg'}/>
                        <img src={Slider_img_10} alt="" className={'bg'}/>
                        <img src={Slider_img_11} alt="" className={'bg'}/>
                    </Slider>
                    <Container>
                        {/*<form name="theForm" method="post" action="https://bear.hellomoving.com/wc.dll?mpdir~moduleret~STARUSA~J~SKIPINV">*/}
                        {/*    <input type="HIDDEN" name="MODULE" value="EST"/>*/}
                        {/*    <input type="HIDDEN" name="REF" value="Website"/>*/}
                        {/*    <span>Customer Name:</span>*/}
                        {/*    <input type="text" className="textFields" name="SNAME" size="20" tabIndex="1"/>*/}
                        {/*    <span>Customer Email:</span>*/}
                        {/*    <input type="text" class="textFields" name="EMAIL" size="20" tabindex="2"/>*/}
                        {/*    <span>Contact Phone:</span>*/}
                        {/*    <input type="text" class="textFields" name="STELH" size="20"*/}
                        {/*           tabindex="3"/>*/}
                        {/*    <span>Move Date:</span>*/}
                        {/*    <input type="text" class="textFields" name="MOVEDATE" size="10"*/}
                        {/*           tabindex="5"/>*/}
                        {/*    <span>Moving from Zip Code:</span>*/}
                        {/*    <input type="text" class="textFields" name="SZIP" size="7" tabindex="8"/>*/}
                        {/*    <span>Moving to Zip Code:</span>*/}
                        {/*    <input type="text" class="textFields" name="RZIP" size="7" tabindex="9"/>*/}
                        {/*    <span>Number of Rooms:</span>*/}
                        {/*    <select name="ROOMS" className="textFields3" tabIndex="10">*/}
                        {/*        <option selected>Select number of rooms</option>*/}
                        {/*        <option value="STU">Studio</option>*/}
                        {/*        <option value="ONE">One bedroom</option>*/}
                        {/*        <option value="TWO">Two bedrooms</option>*/}
                        {/*        <option value="THR">Three bedrooms</option>*/}
                        {/*        <option value="FOU">Four bedrooms</option>*/}
                        {/*        <option value="FIV">Five bedrooms</option>*/}
                        {/*        <option value="SIX">Six bedrooms and more</option>*/}
                        {/*    </select>*/}
                        {/*    <input type="submit" name="btnSubmit" value="Get Your Quote" tabindex="10"/>*/}
                        {/*</form>*/}
                        <form action="https://bear.hellomoving.com/wc.dll?mpdir~moduleret~STARUSA~J~SKIPINV" data-aos="fade-left" method="post" name="theForm">
                            <input type="HIDDEN" name="REF" value="Website" readOnly/>
                            <h1 className={'main-title'}>GET A FREE QUOTE AND SAVE!</h1>
                            <div className="form-group">
                                <label className={'form-label'}>Customer Name *</label>
                                <input type="text" className="form-control" name="SNAME" placeholder={'Enter your full name'}/>
                            </div>
                            <div className="form-group">
                                <label className={'form-label'}>Customer Email *</label>
                                <input type="text" className="form-control" name="EMAIL" placeholder={'Enter Your Email'}/>
                            </div>
                            <div className="form-group">
                                <label className={'form-label'}>Contact Phone *</label>
                                <input type="text" className="form-control" name="STELH" placeholder={'Enter Phone Number'}/>
                            </div>
                            <div className="form-group">
                                <label className={'form-label'}>Move Date *</label>
                                <input type="date" className="form-control" name="MOVEDATE" placeholder={'03/21/2023'}/>
                            </div>
                            <div className="form-group">
                                <label className={'form-label'}>Moving From Zip Code *</label>
                                <input type="text" className="form-control" name="SZIP" placeholder={'Enter zip code here'}/>
                            </div>
                            <div className="form-group">
                                <label className={'form-label'}>Moving To Zip Code *</label>
                                <input type="text" className="form-control" name="RZIP" placeholder={'Enter zip code here'}/>
                            </div>
                            <div className="form-group">
                                <label className={'form-label'}>Moving To Zip Code *</label>
                                <select className="form-select" name="ROOMS" defaultValue={'Selected'}>
                                    <option value="Selected">Select number of rooms</option>
                                    <option value="STU">Studio</option>
                                    <option value="ONE">One bedroom</option>
                                    <option value="TWO">Two bedrooms</option>
                                    <option value="THR">Three bedrooms</option>
                                    <option value="FOU">Four bedrooms</option>
                                    <option value="FIV">Five bedrooms</option>
                                    <option value="SIX">Six bedrooms and more</option>
                                </select>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="Yes" id="agree_opt_in_consent"/>
                                <label className={'form-check-label'} htmlFor="agree_opt_in_consent">I have read and agree to the <NavLink to="/opt-in-consent" onClick={this.toggleOpen}>Opt In Consent</NavLink></label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="Yes" id="agree_sms"/>
                                <label className={'form-check-label'} htmlFor="agree_sms">I consent to receive information by SMS</label>
                            </div>
                            <button className={'btn btn-primary btn-lg btn-submit'} name="btnSubmit">Get Your Quote</button>
                        </form>
                        {/*<form action="#" data-aos="fade-left" onSubmit={this.handleSubmit}>*/}
                        {/*    <h1 className={'main-title'}>GET A FREE QUOTE AND SAVE!</h1>*/}
                        {/*    <div className="form-group">*/}
                        {/*        <label className={'form-label'}>Your Full Name *</label>*/}
                        {/*        <input type="text" className="form-control" placeholder={'Enter your full name'}/>*/}
                        {/*    </div>*/}
                        {/*    <div className="form-group">*/}
                        {/*        <label className={'form-label'}>Moving From Zip Code *</label>*/}
                        {/*        <input type="text" className="form-control" placeholder={'Enter zip code here'}/>*/}
                        {/*    </div>*/}
                        {/*    <div className="form-group">*/}
                        {/*        <label className={'form-label'}>Moving To Zip Code *</label>*/}
                        {/*        <input type="text" className="form-control" placeholder={'Enter zip code here'}/>*/}
                        {/*    </div>*/}
                        {/*    <div className="form-group">*/}
                        {/*        <label className={'form-label'}>Phone *</label>*/}
                        {/*        <input type="text" className="form-control" placeholder={'Enter Phone Number'}/>*/}
                        {/*    </div>*/}
                        {/*    <div className="form-group">*/}
                        {/*        <label className={'form-label'}>Move Date *</label>*/}
                        {/*        <input type="date" className="form-control" placeholder={'03/21/2023'}/>*/}
                        {/*    </div>*/}
                        {/*    <button className={'btn btn-primary btn-lg btn-submit'}>Get Your Quote Now Anas</button>*/}
                        {/*</form>*/}
                    </Container>
                </section>
                <Modal show={this.state.thank_you} onHide={this.thank_you_modal_close} className={'thank_you_modal'} centered>
                    <Modal.Body>
                        <Modal.Title>Thank you!</Modal.Title>
                        <p>One of our representatives will contact you shortly.</p>
                        <button onClick={this.thank_you_modal_close}>Ok</button>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }

}
