import React, { Component } from "react";
import {Container} from "react-bootstrap";
import AOS from 'aos';
import 'aos/dist/aos.css';
import HeroForm from "../component/Form";
import Modal from "react-bootstrap/Modal";

import PhoneIcon from './../assets/img/phone-icon.png';
import EmailIcon from './../assets/img/email.png';


export default class ContactUs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: false,
            thank_you: false,
        };
    }
    loaderShow() {
        window.scrollTo(0, 0);
        this.setState({loader: true});
        setTimeout(() => {
            this.setState({loader: false});
        }, 1500);
    };
    componentDidMount() {
        this.loaderShow();
        AOS.init({
            once: true,
        });
    }
    handleSubmit = (event) => {
        event.preventDefault();
        this.setState({ thank_you: true });
    };
    thank_you_modal_close = () => {
        this.setState({ thank_you: false });
    };
    render() {
        return (
            <main>
                {
                    this.state.loader === true ? <div className={'loader'}><div className={'bar'}><span></span></div></div> : ''
                }
                <HeroForm />
                <section className={'form-wrapper'}>
                    <Container>
                        <div className="row">
                            <div className="col-lg-10 offset-lg-1">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="card card-form" data-aos="fade-right">
                                            <div className="card-body">
                                                <form action="#" onSubmit={this.handleSubmit}>
                                                    <div className="form-group">
                                                        <label className={'form-label'}>Your Full Name *</label>
                                                        <input type="text" className="form-control" placeholder={'Enter your full name'}/>
                                                    </div>
                                                    <div className="form-group">
                                                        <label className={'form-label'}>Your Email *</label>
                                                        <input type="text" className="form-control" placeholder={'Enter Your Email'}/>
                                                    </div>
                                                    <div className="form-group">
                                                        <label className={'form-label'}>Phone *</label>
                                                        <input type="text" className="form-control" placeholder={'Enter Your Phone'}/>
                                                    </div>
                                                    <div className="form-group">
                                                        <label className={'form-label'}>Subject *</label>
                                                        <input type="text" className="form-control" placeholder={'Enter Your Subject'}/>
                                                    </div>
                                                    <div className="form-group">
                                                        <label className={'form-label'}>Message *</label>
                                                        <input type="text" className="form-control" placeholder={'Enter Your Comment here'}/>
                                                    </div>
                                                    <button className={'btn btn-primary btn-lg btn-submit'}>Get Your Quote Now</button>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="card card-info" data-aos="fade-left">
                                            <div className="card-body">
                                                <div className={'text-center'}>
                                                    <h4 className={'title'}>Star USA Moving</h4>
                                                    <p>5952 Peachtree industrial <br/> Blvd #15 Norcross GA 30071</p>
                                                    <a href={'tel:6784628392'}><img src={PhoneIcon} alt="" className={'icon'}/> Tell: 678-462-8392</a>
                                                    <a href={'mailto:sales@starusamoving.com'}><img src={EmailIcon} alt="" className={'icon'}/> Email: Sales@StarUSAMoving.com</a>
                                                </div>
                                                <div className={'text-center'}>
                                                    <h4 className={'title'}>Office Hours:</h4>
                                                    <p>Monday - Friday: 8:00 - 5:00 pm. <br/>
                                                        Saturday: 8:00 - 2:00 pm.<br/>
                                                        Sunday: Closed</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Container>
                </section>
                <section className={'map'} data-aos="fade-up">
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3309.6067160014086!2d-84.22840608421926!3d33.9512420304921!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88f5a120274df4af%3A0x77d5eff1b34241ee!2s5952%20Peachtree%20Industrial%20Blvd%20%23%2015%2C%20Norcross%2C%20GA%2030071%2C%20USA!5e0!3m2!1sen!2s!4v1680096592366!5m2!1sen!2s"
                        allowFullScreen="" loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"></iframe>
                </section>
                <Modal show={this.state.thank_you} onHide={this.thank_you_modal_close} className={'thank_you_modal'} centered>
                    <Modal.Body>
                        <Modal.Title>Thank you!</Modal.Title>
                        <p>One of our representatives will contact you shortly.</p>
                        <button onClick={this.thank_you_modal_close}>Ok</button>
                    </Modal.Body>
                </Modal>
            </main>
        );
    }
}