import React, { Component } from "react";
import {Container} from "react-bootstrap";
import AOS from 'aos';
import 'aos/dist/aos.css';
import HeroForm from "../component/Form";



import LeftSideImage from './../assets/img/about-3.jpg';

import About1 from "../assets/img/about-1.jpg";
import About2 from "../assets/img/about-2.jpg";

import CTAImg from "../assets/img/cta-img.jpg";

import girlBoxInHand from "../assets/img/girl-box-in-hand.jpg";
import girlBoxInHandBg from "../assets/img/girl-box-in-hand-bg.jpg";



export default class About extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: false,
        };
    }
    loaderShow() {
        window.scrollTo(0, 0);
        this.setState({loader: true});
        setTimeout(() => {
            this.setState({loader: false});
        }, 1500);
    };

    componentDidMount() {
        this.loaderShow();
        AOS.init({
            once: true,
        });
    }
    render() {
        return (
            <main>
                {
                    this.state.loader === true ? <div className={'loader'}><div className={'bar'}><span></span></div></div> : ''
                }
                <section className={'text-section'}>
                    <div className="container">
                        <div className="content" data-aos="fade-right">
                            <h1 className={'title text-center mb-4'}>Privacy Policy</h1>
                            <p>This Privacy Policy ("Policy") applies to https://starusamoving.com, and Star USA Moving LLC ("Company") and governs data collection and usage. For the purposes of this Privacy Policy, unless otherwise noted, all references to the Company include https://starusamoving.com. The Company's website is a news and information site. By using the Company website, you consent to the data practices described in this statement.</p>
                            <h2 className={'title mb-4'}>Collection of your Personal Information</h2>
                            <p>In order to better provide you with products and services offered, the Company may collect personally identifiable information, such as your:</p>
                            <ul>
                                <li>First and last name</li>
                                <li>Mailing address</li>
                                <li>Email address</li>
                                <li>Phone number</li>
                            </ul>
                            <p>We do not collect any personal information about you unless you voluntarily provide it to us. However, you may be required to provide certain personal information to us when you elect to use certain products or services. These may include: (a) registering for an account; (b) entering a sweepstakes or contest sponsored by us or one of our partners; (c) signing up for special offers from selected third parties; (d) sending us an email message; (e) submitting your credit card or other payment information when ordering and purchasing products and services. To wit, we will use your information for, but not limited to, communicating with you in relation to services and/or products you have requested from us. We also may gather additional personal or non-personal information in the future.</p>
                            <h2 className={'title mb-4'}>Use of your Personal Information</h2>
                            <p>The Company collects and uses your personal information in the following ways:</p>
                            <ul>
                                <li>to operate and deliver the services you have requested</li>
                                <li>to provide you with information, products, or services that you request from us</li>
                                <li>to provide you with notices about your account</li>
                                <li>to carry out the Company's obligations and enforce our rights arising from any contracts entered between</li>
                                <li>to notify you about changes to our https://starusamoving.com or any products or services we offer or pro-in any other way we may describe when you provide the information</li>
                                <li>for any other purpose with your consent.</li>
                            </ul>
                            <p>The Company may also use your personally identifiable information to inform you of other products or services available from the Company and its affiliates.</p>
                            <h2 className={'title mb-4'}>Sharing Information with Third Parties</h2>
                            <p>The Company does not sell, rent, or lease its customer lists to third parties.</p>
                            <h2 className={'title mb-4'}>Use of Cookies</h2>
                            <p>The Company's website may use "cookies" to help you personalize your online experience. A cookie is a text file that is placed on your hard disk by a web page server. Cookies cannot be used to run programs or deliver viruses to your computer. Cookies are uniquely assigned to you, and can only be read by a web server in the domain that issued the cookie to you.</p>
                            <p>One of the primary purposes of cookies is to provide a convenience feature to save you time. The purpose of a cookie is to tell the web server that you have returned to a specific page. For example, if you personalize the Company's pages, or register with Company's site or services, a cookie helps the Company to recall your specific information on subsequent visits. This simplifies the process of recording your personal information, such as billing addresses, shipping addresses, and so on. When you return to the same website, the information you previously provided can be retrieved, so you can easily use the Company's features that you customized.</p>
                            <p>You have the ability to accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify your browser settings to decline cookies if you prefer. If you choose to decline cookies, you may not be able to fully experience the interactive features of the Company's services or websites you visit.</p>
                            <h2 className={'title mb-4'}>Security of your Personal Information</h2>
                            <p>The Company secures your personal information from unauthorized access, use, or disclosure. The Company uses the following methods for this purpose:</p>
                            <ul>
                                <li>SSL Protocol</li>
                            </ul>
                            <p>When personal information (such as a credit card number) is transmitted to other websites, it is protected through the use of encryption, such as the Secure Sockets Layer (SSL) protocol.</p>
                            <p>We strive to take appropriate security measures to protect against unauthorized access to or alteration of your personal information. Unfortunately, no data transmission over the Internet or any wireless network can be guaranteed to be 100% secure. As a result, while we strive to protect your personal information, you acknowledge that: (a) there are security and privacy limitations inherent to the Internet that are beyond our control; and (b) the security, integrity, and privacy of any and all information and data exchanged between you and us through this site cannot be guaranteed.</p>
                            <h2 className={'title mb-4'}>Right to Deletion</h2>
                            <p>Subject to certain exceptions set out below, on receipt of a verifiable request from you, we will:</p>
                            <ul>
                                <li>Delete your personal information from our records; and</li>
                                <li>Direct any service providers to delete your personal information from their records.</li>
                            </ul>
                            <p>Please note that we may not be able to comply with requests to delete your personal information if it is necessary to:</p>
                            <ul>
                                <li>Complete the transaction for which the personal information was collected, fulfill the terms of a written</li>
                                <li>Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity; or prosecute</li>
                                <li>Debug to identify and repair errors that impair existing intended functionality;</li>
                                <li>Exercise free speech, ensure the right of another consumer to exercise his or her right of free speech, or</li>
                                <li>Comply with the California Electronic Communications Privacy Act;</li>
                                <li>Engage in public or peer-reviewed scientific, historical, or statistical research in the public interest that ad</li>
                                <li>Enable solely internal uses that are reasonably aligned with your expectations based on your relationship</li>
                                <li>Comply with an existing legal obligation; or</li>
                                <li>Otherwise use your personal information, internally, in a lawful manner that is compatible with the context</li>
                            </ul>
                            <h2 className={'title mb-4'}>Children Under Thirteen</h2>
                            <p>The Company does not knowingly collect personally identifiable information from children under the age of 13. If you are under the age of 13, you must ask your parent or guardian for permission to use this website.</p>
                            <h2 className={'title mb-4'}>Email Communications</h2>
                            <p>From time to time, the Company may contact you via email for the purpose of providing announcements, promotional offers, alerts, confirmations, surveys, and/or other general communication.</p>
                            <p>If you would like to stop receiving marketing or promotional communications via email from the Company, you may opt out of such communications by replying STOP.</p>
                            <h2 className={'title mb-4'}>Changes to This Statement</h2>
                            <p>The Company reserves the right to change this Policy from time to time. For example, when there are changes in our services, changes in our data protection practices, or changes in the law. When changes to this Policy are significant, we will inform you. You may receive a notice by sending an
                                email to the primary email address specified in your account, by placing a prominent notice on our Star USA Moving LLC, and/or by updating any privacy information. Your continued use of the website and/or services available after such modifications will constitute your: (a) acknowledgment of the modified Policy; and (b) agreement to abide and be bound by that Policy.</p>
                            <h2 className={'title mb-4'}>Contact Information</h2>
                            <p>The Company welcomes your questions or comments regarding this Policy. If you believe that the Company has not adhered to this Policy, please contact the Company at:</p>
                            <p>Star USA Moving LLC<br/>
                                5952 Peachtree Industrial Blvd # 15<br/>
                                Norcross, Georgia 30071</p>
                            <p>Email Address: sales@starusamoving.com<br/>
                                Phone Number: 678-462-8392<br/>
                                Effective as of April 03, 2024</p>
                        </div>
                    </div>
                </section>
            </main>
        );
    }
}