import React, { Component } from "react";
import {Container} from "react-bootstrap";
import AOS from 'aos';
import 'aos/dist/aos.css';
import HeroForm from "../component/Form";



import LeftSideImage from './../assets/img/about-3.jpg';

import About1 from "../assets/img/about-1.jpg";
import About2 from "../assets/img/about-2.jpg";

import CTAImg from "../assets/img/cta-img.jpg";

import girlBoxInHand from "../assets/img/girl-box-in-hand.jpg";
import girlBoxInHandBg from "../assets/img/girl-box-in-hand-bg.jpg";



export default class About extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: false,
        };
    }
    loaderShow() {
        window.scrollTo(0, 0);
        this.setState({loader: true});
        setTimeout(() => {
            this.setState({loader: false});
        }, 1500);
    };

    componentDidMount() {
        this.loaderShow();
        AOS.init({
            once: true,
        });
    }
    render() {
        return (
            <main>
                {
                    this.state.loader === true ? <div className={'loader'}><div className={'bar'}><span></span></div></div> : ''
                }
                <section className={'text-section'}>
                    <div className="container">
                        <div className="content" data-aos="fade-right">
                            <h1 className={'title text-center mb-4'}>Opt-In Consent</h1>
                            <p>Thank you for clarifying. Here's the adapted content:</p>
                            <h2 className={'title mb-4'}>LET'S GET MOVING WITH YOUR REQUEST!</h2>
                            <p>We're excited to be part of your move! To ensure we provide you with exceptional service, we'll need to collect and store your information. We may also record phone calls for training purposes. Rest assured, any information we collect or record is solely for coordinating the services you request and ensuring they meet our high standards.
                                By checking the box below, you authorize Star USA Moving LLC to contact you via email, phone, or text, including through automated technology. Please feel free to request to have your information provided to you or deleted at any time. If you prefer not to receive certain types of communication, reply "stop" or "unsubscribe" or ask your move consultant to update your preferences.
                                We're here to make your move as smooth as possible. If you have any questions or prefer to chat, call us at 678-462-8392.</p>
                            <h2 className={'title mb-4'}>Changes to Terms</h2>
                            <p>Star USA Moving reserves the right, in its sole discretion, to change the Terms under which https://starusamoving.com is offered. The most current version of the Terms will supersede all previous versions. Star USA Moving encourages you to periodically review the Terms to stay informed of our updates.</p>
                            <h2 className={'title mb-4'}>Contact Information</h2>
                            <p>Star USA Moving welcomes your questions or comments regarding the Terms:</p>
                            <p>Star USA Moving LLC<br/>
                                5952 Peachtree Industrial Blvd # 15<br/>
                                Norcross, Georgia 30071</p>
                            <p>Email Address: sales@starusamoving.com<br/>
                                Phone Number: 678-462-8392<br/>
                                Effective as of April 03, 2024</p>
                        </div>
                    </div>
                </section>
            </main>
        );
    }
}