import { BrowserRouter, Routes, Route } from "react-router-dom";
import './style.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import Layout from './pages/Layout';
import Home from './pages/Home';
import About from './pages/About';
import LongDistanceMoves from './pages/LongDistanceMoves';
import StorageServices from './pages/StorageServices';
import GetEstimate from './pages/GetEstimate';
import ContactUs from './pages/ContactUs';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsAndCondition from './pages/TermsAndCondition';
import OptInConsent from './pages/OptInConsent';
import PageNotFound from './pages/404';

function App() {
  return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="about-us" element={<About />} />
            <Route path="long-distance-moves" element={<LongDistanceMoves />} />
            <Route path="storage-service" element={<StorageServices />} />
            <Route path="get-estimate" element={<GetEstimate />} />
            <Route path="contact-us" element={<ContactUs />} />
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
            <Route path="terms-and-condition" element={<TermsAndCondition />} />
            <Route path="opt-in-consent" element={<OptInConsent />} />
            <Route path="*" element={<PageNotFound />} />
          </Route>
        </Routes>
      </BrowserRouter>
  );
}

export default App;
