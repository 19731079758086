import React, { Component } from "react";
import {Container} from "react-bootstrap";
import AOS from 'aos';
import 'aos/dist/aos.css';
import HeroForm from "../component/Form";

import Family1 from './../assets/img/family5.jpg';

import Family2 from './../assets/img/family4.jpg';
import Family6 from './../assets/img/family6.jpg';


export default class StorageServices extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: false,
        };
    }
    loaderShow() {
        window.scrollTo(0, 0);
        this.setState({loader: true});
        setTimeout(() => {
            this.setState({loader: false});
        }, 1500);
    };
    componentDidMount() {
        this.loaderShow();
        AOS.init({
            once: true,
        });
    }
    render() {
        return (
            <main>
                {
                    this.state.loader === true ? <div className={'loader'}><div className={'bar'}><span></span></div></div> : ''
                }
                <HeroForm />
                <section className={'card-horizontal-simple'}>
                    <div className="container">
                        <div className="row align-items-lg-center">
                            <div className="col-lg-6">
                                <img src={Family2} alt="" className={'img-fluid'} data-aos="fade-up"/>
                            </div>
                            <div className="col-lg-6">
                                <div className="content" data-aos="fade-left">
                                    <h1 className={'mb-4'}>Storage Services</h1>
                                    <p>Our company recognizes that we are not simply selling a product or a service, but an assurance of excellence, professionalism and most of all care. At Star USA Moving we understand when it is time to choose a company to handle and preserve your personal affections, why would you settle for anything less than the assurance of quality, care and reliance.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className={'card-horizontal v2'}>
                    <img src={Family1} alt="" className={'right-img'} data-aos="fade-up"/>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="content" data-aos="fade-right">
                                    <h1 className={'title'}>Our service includes:</h1>
                                    <ul className={'check-list'}>
                                        <li>Temporary or long term storage</li>
                                        <li>State of the art security to ensure the protection of your valuable items</li>
                                        <li>Climate controlled units</li>
                                        <li>Locations through major thoroughfares</li>
                                        <li>Diversity of unit sizes</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className={'cta_2 p-5'}>
                    <div className="text-center">
                        <a href="#get-your-quote-now" className={'btn btn-primary btn-xl'}>Get Your Quote Now</a>
                    </div>
                </section>
                <section className={'card-horizontal-with-bg'}>
                    <div className="container">
                        <div className="content-wrapper">
                            <img src={Family6} alt="" className={'img-fluid left-img'} data-aos="fade-up"/>
                            <div className="content" data-aos="fade-left">
                                <p>Recreational vehicle storage accessibilityOur professional staff is proficient in all aspects of storage supply and delivery services and operates in conjunction with our state of the art facilities to service any individual prerequisites for storage amenities.</p>
                                <br/>
                                <p>Our “Clean and Green” stance maintains our property’s standard for clean and efficient productivity, and our continual endeavor to provide efficiency and excellence ensures that your personal belongings remain in a safe and secure environment. If necessary, our storage experts are available for any inquiries or problems that may arise, and will not hesitate to address any and all of your concerns.</p>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        );
    }
}