import React, { Component } from "react";
import {Container} from "react-bootstrap";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import AOS from 'aos';
import 'aos/dist/aos.css';
import HeroForm from './../component/Form'


import Card1 from './../assets/img/card-img-1.jpg';
import Card2 from './../assets/img/card-img-2.jpg';
import Card3 from './../assets/img/card-img-3.jpg';

import Logo from './../assets/img/logo.png';
import Family1 from './../assets/img/family.jpg';
import Courier from './../assets/img/courier.jpg';

import Family2 from './../assets/img/family2.jpg';

import Stars from './../assets/img/rating.png';



var settings = {
    infinite: true,
    arrows: false,
    dots: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};

export default class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: false,
        };
    }

    loaderShow() {
        window.scrollTo(0, 0);
        this.setState({loader: true});
        setTimeout(() => {
            this.setState({loader: false});
        }, 1500);
    };

    componentDidMount() {
        this.loaderShow();
        AOS.init({
            once: true,
        });

        const apiKey = 'YOUR_API_KEY';
        const apiURL = 'https://emailvalidation.abstractapi.com/v1/?api_key=' + apiKey;
        const sendEmailValidationRequest = async (email) => {
            try {
                const response = await fetch(apiURL + '&email=' + email);
                const data = await response.json();
                const isValidSMTP = data.is_smtp_valid.value;
                console.log(response)
                if (isValidSMTP) {
                    // use the email address in the mailto link
                } else {
                    // show the user an error
                }
            } catch (error) {
                throw error;
            }
        };
    }

    render() {
        return (
            <main>
                {
                    this.state.loader === true ? <div className={'loader'}><div className={'bar'}><span></span></div></div> : ''
                }
                <HeroForm />
                <section className={'cards'}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4">
                                <div className="card" data-aos="fade-right">
                                    <img src={Card1} alt=""/>
                                    <div className="card-body">
                                        <h4 className="card-title">Need an Atlanta Moving Company?</h4>
                                        <p>Star USA Moving is here to help! Star USA Moving & Storage is a moving company with a premier location in Atlanta, GA</p>
                                        <Link to="about-us">Read More</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="card">
                                    <img src={Card2} alt=""/>
                                    <div className="card-body">
                                        <h4 className="card-title">Full Service Moving Company</h4>
                                        <p>Whether you are up sizing, downsizing, buying a new home or relocating your office, having a local Atlanta moving company is vital.</p>
                                        <Link to="about-us">Read More</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="card" data-aos="fade-left">
                                    <img src={Card3} alt=""/>
                                    <div className="card-body">
                                        <h4 className="card-title">Stress Free Packing Services</h4>
                                        <p>For anyone who has ever moved, you already know that ‘stress-free packing’ is an oxymoron. So why not let us handle the packing</p>
                                        <Link to="about-us">Read More</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className={'card-horizontal'}>
                    <img src={Family1} alt="" className={'right-img'}/>
                    <img src={Courier} alt="" className={'bg-img'}/>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="content" data-aos="fade-right">
                                    <img src={Logo} alt="" className={'logo'}/>
                                    <p>Star USA Moving is a fully licensed and insured moving & storage Company. With our impeccable reputation and years of service Star USA Moving is proud to be the full-service moving and storage company of choice for both residential and commercial clients.</p>
                                    <p>We understand the stressful conditions that are inevitably experienced with every move and as such remain committed to alleviating these conditions. Our trained professional staff will ensure that each move is an efficient process that will remain within the confines of your budgetary limitations.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className={'cta_2 p-5'}>
                    <div className="text-center">
                        <a href="#get-your-quote-now" className={'btn btn-primary btn-xl'}>Get Your Quote Now</a>
                    </div>
                </section>
                <section className={'card-horizontal-simple pb-5'}>
                    <div className="container">
                        <div className="row align-items-lg-center">
                            <div className="col-lg-6">
                                <img src={Family2} alt="" className={'img-fluid'} data-aos="fade-up"/>
                            </div>
                            <div className="col-lg-6">
                                <div className="content" data-aos="fade-left">
                                    <p>Our company recognizes that we are not simply selling a product or a service, but an assurance of excellence, professionalism and most of all care. At Star USA Moving we understand when it is time to choose a company to handle and preserve your personal affections, why would you settle for anything less than the assurance of quality, care and reliance.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className={'reviews'}>
                    <Container>
                        <h1 className="main-title">Customer Reviews</h1>
                        <Slider {...settings}>
                            <div className="card" data-aos="fade-right">
                                <div className="card-body">
                                    <div className="ratings">
                                        <img src={Stars} alt=""/>
                                    </div>
                                    <p>In my opinion, no moving company strives for customer satisfaction the way Star USA Moving does. I belong to a family where relocation is nothing new. They have helped me move so many times over the last 8 years. So for my last move I naturally hired Star USA Moving. They perform exceptionally on the field. They train their men very well and the crew is very helpful. I have never seen or heard of them being late. Their quotes are always on point too. This company really understands how important it is to satisfy the client and they always deliver on the promise.</p>
                                </div>
                            </div>
                            <div className="card" data-aos="fade-left">
                                <div className="card-body">
                                    <div className="ratings">
                                        <img src={Stars} alt=""/>
                                    </div>
                                    <p>Most of my furniture is old and cheap junks. But that was what Star USA Moving had to move. I knew it was a hard job given how less-than-standard these pieces are and how susceptible to journey they are. But the movers managed to deliver each and every piece of my grandma’s furniture in great condition to my new house. They didn’t even charge me ridiculously for it. There are many moving companies out there with shiny vans and uniformed movers. Star USA Moving really cares for your valuables and that’s what makes them unrivaled.</p>
                                </div>
                            </div>
                        </Slider>
                    </Container>
                </section>
            </main>
        );
    }
}