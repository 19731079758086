import React, { Component } from "react";
import {Container} from "react-bootstrap";
import AOS from 'aos';
import 'aos/dist/aos.css';
import HeroForm from "../component/Form";



import LeftSideImage from './../assets/img/about-3.jpg';

import About1 from "../assets/img/about-1.jpg";
import About2 from "../assets/img/about-2.jpg";

import CTAImg from "../assets/img/cta-img.jpg";

import girlBoxInHand from "../assets/img/girl-box-in-hand.jpg";
import girlBoxInHandBg from "../assets/img/girl-box-in-hand-bg.jpg";



export default class About extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: false,
        };
    }
    loaderShow() {
        window.scrollTo(0, 0);
        this.setState({loader: true});
        setTimeout(() => {
            this.setState({loader: false});
        }, 1500);
    };

    componentDidMount() {
        this.loaderShow();
        AOS.init({
            once: true,
        });
    }
    render() {
        return (
            <main>
                {
                    this.state.loader === true ? <div className={'loader'}><div className={'bar'}><span></span></div></div> : ''
                }
                <HeroForm />
                <section className={'horizontal-card-v2 v2 pb-0'}>
                    <div className="left-img">
                        <img src={LeftSideImage} alt="" data-aos="fade-up"/>
                    </div>
                    <div className="content" data-aos="fade-left">
                        <h1 className={'title'}>About us</h1>
                        <p>Star USA Moving is a moving company with a premier location in Atlanta, GA. We offer competitive pricing and the best customer service in the industry. Our Atlanta movers are fully licensed, bonded and insured, and we guarantee professional service with no hidden fees. Our moving truck fleet is well maintained and each truck is equipped with a box dolly, appliance dolly, 4-wheel dolly, ramps, 50+ moving blankets, and ratchet straps to ensure the highest level of protection for every item we move. We also guarantee clean-cut movers who have undergone background checks and maintain our company standards of absolute professionalism and attention to detail. When you hire Star USA Moving, you hire movers who will arrive on time and on task.</p>
                    </div>
                </section>
                <section className={'horizontal-card-v3'}>
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="content" data-aos="fade-right">
                                    <h1 className={'title mb-4'}>Full-service moving company</h1>
                                    <p>Whether you are upsizing, downsizing, buying a new home or relocating your office, having a local Atlanta moving company is vital to a fast and reliable move. Star USA Moving provides full-service moving options and prompt communication with you regarding every step of the move. Our goal is to make your move as stress-free as possible, which is why our movers always have the right supplies on hand for use, including boxes, packing material and tape. You also don’t need to worry about disassembling and reassembling furniture or disconnecting and reconnecting appliances. Our team captains carry tool kits with tools that might be needed for all of these tasks and more.</p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="img-wrapper">
                                    <img src={About1} alt="" className={'img-1'} data-aos="fade-up"/>
                                    <img src={About2} alt="" className={'img-2'} data-aos="fade-up" data-aos-delay="300"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className={'cta_2 p-5'}>
                    <div className="text-center">
                        <a href="#get-your-quote-now" className={'btn btn-primary btn-xl'}>Get Your Quote Now</a>
                    </div>
                </section>
                <section className={'horizontal-card-v4'}>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-7 col-xl-6">
                                <div className="content" data-aos="fade-right">
                                    <div>
                                        <h1 className="title">Stress-free packing</h1>
                                        <p>For anyone who has ever moved, you already know that ‘stress-free packing’ is an oxymoron. So why not let us handle the packing and unpacking for you? Our movers have been professionally trained to carefully pack up your home or office, ensuring that nothing is broken or damaged, and set up at the new location. You don’t have to lift a finger!</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5 col-xl-6">
                                <div className="img-wrapper" data-aos="fade-up">
                                    <img src={girlBoxInHand} alt="" className={'img-1'}/>
                                    <img src={girlBoxInHandBg} alt="" className={'img-2'}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className={'cta'}>
                    <Container>
                        <div className="card">
                            <div className="row align-items-lg-center">
                                <div className="col-lg-6">
                                    <div className="content" data-aos="fade-right">
                                        <h1 className="title">Call us today for all your <br/> Atlanta moving needs</h1>
                                        <p>Star USA Moving can help you complete both residential and commercial moves in Atlanta and surrounding areas, as well as long-distance moves to and from Atlanta. Let us help make your next move as stress-free as possible.</p>
                                    </div>
                                </div>
                                <div className="col-lg-5 offset-lg-1">
                                    <img src={CTAImg} alt="" className={'img-fluid'} data-aos="fade-up"/>
                                </div>
                            </div>
                        </div>
                    </Container>
                </section>
            </main>
        );
    }
}