import React, { Component, props } from "react";
import {Link, NavLink} from "react-router-dom";
import { useLocation } from 'react-router-dom';
import {
    FacebookShareButton,
    FacebookIcon,
    TwitterShareButton,
    TwitterIcon,
    EmailShareButton,
    EmailIcon
} from "react-share";


import './../assets/scss/footer.scss'
// Images ==================
import Logo from './../assets/img/logo-light.png';
import Facebook from './../assets/img/facebook.png';
import Twitter from './../assets/img/twitter.png';
import Envelope from './../assets/img/envelope.png';
import Printer from './../assets/img/printer.png';
import Plus from './../assets/img/plus.png';
import {Container, Nav} from "react-bootstrap";
import Home from "../assets/img/home.png";



export default class Footer extends Component {
    // constructor(props) {
    //     super(props);
    //     this.state = {
    //         activeTab: '',
    //     };
    // }
    // componentDidMount() {
    //     const location = useLocation();
    //     const pathName = location.pathname;
    //     // const { pathname } = this.props.location;
    //     alert(pathName)
    //     // this.setActiveTab(pathname);
    // }
    // componentDidUpdate(prevProps) {
        // const { pathname } = this.props.location;
        // if (pathname !== prevProps.location.pathname) {
        //     this.setActiveTab(pathname);
        // }
    // }
    // setActiveTab(pathname) {
    //     if (pathname === '/about-us') {
    //         this.setState({ activeTab: 'about' });
    //     } else if (pathname === '/contact-us') {
    //         this.setState({ activeTab: 'contact' });
    //     } else {
    //         this.setState({ activeTab: 'home' });
    //     }
    // }

    render() {
        // const { activeTab } = this.state;
        const shareUrl = 'http://star-usa-moving.myworldex.com/';
        const title = 'Star USA Moving';

        const year = new Date().getFullYear();
        return (
            <div className="footer">
                {/*<h1>{activeTab}</h1>*/}
                <Link className={'logo'} to="/" onClick={this.pageTop}>
                    <img src={Logo} alt="Prestige" />
                </Link>
                <div className="bottom">
                    <p>© All Rights Reserved Star USA Moving POWERED BY <a href="https://www.rewardLion.com">RewardLion</a> {year}</p>
                    <br/>
                    <p>5952 Peachtree industrial Blvd #15 Norcross GA 30071</p>
                    <ul className="social">
                        <li>
                            <FacebookShareButton
                                url={shareUrl}
                                quote={title}
                                className="Demo__some-network__share-button">
                                <FacebookIcon size={45} round />
                            </FacebookShareButton>
                        </li>
                        <li>
                            <TwitterShareButton
                                url={shareUrl}
                                title={title}
                                className="Demo__some-network__share-button"
                            >
                                <TwitterIcon size={45} round />
                            </TwitterShareButton>
                        </li>
                        <li>
                            <EmailShareButton
                                url={shareUrl}
                                title={title}
                                className="Demo__some-network__share-button"
                            >
                                <EmailIcon size={45} round />
                            </EmailShareButton>
                        </li>
                        <div className={'container'}>
                            <div className={'d-flex align-items-center justify-content-between mt-4 flex-column flex-xxl-row-reverse'}>
                                <Nav className="menu justify-content-center mb-2 mb-xxl-0" navbarScroll>
                                    <NavLink to="/privacy-policy">Privacy Policy</NavLink>
                                    <NavLink to="/terms-and-condition">Terms and Conditions</NavLink>
                                </Nav>
                            </div>
                        </div>
                    </ul>
                </div>
            </div>
        );
    }
}