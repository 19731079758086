import React, { Component } from "react";
import {Container} from "react-bootstrap";
import AOS from 'aos';
import 'aos/dist/aos.css';
import HeroForm from "../component/Form";



import LeftSideImage from './../assets/img/about-3.jpg';

import About1 from "../assets/img/about-1.jpg";
import About2 from "../assets/img/about-2.jpg";

import CTAImg from "../assets/img/cta-img.jpg";

import girlBoxInHand from "../assets/img/girl-box-in-hand.jpg";
import girlBoxInHandBg from "../assets/img/girl-box-in-hand-bg.jpg";



export default class About extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: false,
        };
    }
    loaderShow() {
        window.scrollTo(0, 0);
        this.setState({loader: true});
        setTimeout(() => {
            this.setState({loader: false});
        }, 1500);
    };

    componentDidMount() {
        this.loaderShow();
        AOS.init({
            once: true,
        });
    }
    render() {
        return (
            <main>
                {
                    this.state.loader === true ? <div className={'loader'}><div className={'bar'}><span></span></div></div> : ''
                }
                <section className={'text-section'}>
                    <div className="container">
                        <div className="content" data-aos="fade-right">
                            <h1 className={'title text-center mb-4'}>Terms and Conditions</h1>
                            <h2 className={'title mb-4'}>Agreement between User and https://starusamoving.com</h2>
                            <p>Welcome to https://starusamoving.com. The https://starusamoving.com website (the "Site") is comprised of various web pages operated by Star USA Moving LLC ("Star USA Moving"). https://starusamoving.com is offered to you conditioned on your acceptance without modification of the terms, conditions, and notices contained herein (the "Terms"). Your use of https://starusamoving.com constitutes your agreement to all such Terms. Please read these terms carefully, and keep a copy of them for your reference.</p>
                            <p>https://starusamoving.com is a News and Information Site.</p>
                            <p>The purpose of this website is to provide information about our services and to provide our clients with a free quote.</p>
                            <h2 className={'title mb-4'}>Privacy</h2>
                            <p>Your use of https://starusamoving.com is subject to Star USA Moving's Privacy Policy. Please review our Privacy Policy, which also governs the Site and informs users of our data collection practices.</p>
                            <h2 className={'title mb-4'}>Electronic Communications</h2>
                            <p>Visiting https://starusamoving.com or sending emails to Star USA Moving constitutes electronic communications. You consent to receive electronic communications and you agree that all agreements, notices, disclosures and other communications that we provide to you electronically, via email and on the Site, satisfy any legal requirement that such communications be in writing.</p>
                            <h2 className={'title mb-4'}>Children Under Thirteen</h2>
                            <p>Star USA Moving does not knowingly collect, either online or offline, personal information from persons under the age of thirteen. If you are under 18, you may use https://starusamoving.com only with permission of a parent or guardian.</p>
                            <h2 className={'title mb-4'}>Links to Third Party Sites/Third Party Services</h2>
                            <p>https://starusamoving.com may contain links to other websites ("Linked Sites"). The Linked Sites are not under the control of Star USA Moving and Star USA Moving is not responsible for the contents of any Linked Site, including without limitation any link contained in a Linked Site, or any changes or updates to a Linked Site. Star USA Moving is providing these links to you only as a convenience, and the inclusion of any link does not imply endorsement by Star USA Moving of the site or any association with its operators</p>
                            <p>Certain services made available via https://starusamoving.com are delivered by third party sites and organizations. By using any product, service or functionality originating from the https://starusamoving.com domain, you hereby acknowledge and consent that Star USA Moving may share such information and data with any third party with whom Star USA Moving has a contractual relationship to provide the requested product, service or functionality on behalf of https://starusamoving.com users and customers.</p>
                            <h2 className={'title mb-4'}>No Unlawful or Prohibited Use/Intellectual Property</h2>
                            <p>You are granted a non-exclusive, non-transferable, revocable license to access and use https://starusamoving.com strictly in accordance with these terms of use. As a condition of your use of the Site, you warrant to Star USA Moving that you will not use the Site for any purpose that is unlawful or prohibited by these Terms. You may not use the Site in any manner which could damage, disable, overburden, or impair the Site or interfere with any other party's use and enjoyment of the Site. You may not obtain or attempt to obtain any materials or information through any means not intentionally made available or provided for through the Site.</p>
                            <p>All content included as part of the Service, such as text, graphics, logos, images, as well as the compilation thereof, and any software used on the Site, is the property of Star USA Moving or its suppliers and protected by copyright and other laws that protect intellectual property and proprietary rights. You agree to observe and abide by all copyright and other proprietary notices, legends or other restrictions contained in any such content and will not make any changes thereto.</p>
                            <p>You will not modify, publish, transmit, reverse engineer, participate in the transfer or sale, create derivative works, or in any way exploit any of the content, in whole or in part, found on the Site. Star USA Moving content is not for resale. Your use of the Site does not entitle you to make any unauthorized use of any protected content, and in particular you will not delete or alter any proprietary rights or attribution notices in any content. You will use protected content solely for your personal use, and will make no other use of the content without the express written permission of Star USA Moving and the copyright owner. You agree that you do not acquire any ownership rights in any protected content. We do not grant you any licenses, express or implied, to the intellectual property of Star USA Moving or our licensors except as expressly authorized by these Terms.</p>
                            <h2 className={'title mb-4'}>International Users</h2>
                            <p>The Service is controlled, operated and administered by Star USA Moving from our offices within the USA. If you access the Service from a location outside the USA, you are responsible for compliance with all local laws. You agree that you will not use the Star USA Moving Content accessed through https://starusamoving.com in any country or in any manner prohibited by any applicable laws, restrictions or regulations.</p>
                            <h2 className={'title mb-4'}>Indemnification</h2>
                            <p>You agree to indemnify, defend and hold harmless Star USA Moving, its officers, directors, employees, agents and third parties, for any losses, costs, liabilities and expenses (including reasonable attorney's fees) relating to or arising out of your use of or inability to use the Site or services, any user postings made by you, your violation of any terms of this Agreement or your violation of any rights of a third party, or your violation of any applicable laws, rules or regulations. Star USA Moving reserves the right, at its own cost, to assume the exclusive defense and control of any matter otherwise subject to indemnification by you, in which event you will fully cooperate with Star USA Moving in asserting any available defenses.</p>
                            <h2 className={'title mb-4'}>Liability Disclaimer</h2>
                            <p>THE INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES INCLUDED IN OR AVAILABLE THROUGH THE SITE MAY INCLUDE INACCURACIES OR TYPOGRAPHICAL ERRORS. CHANGES ARE PERIODICALLY ADDED TO THE INFORMATION HEREIN. STAR USA MOVING LLC AND/OR ITS SUPPLIERS MAY MAKE IMPROVEMENTS AND/OR CHANGES IN THE SITE AT ANY TIME.</p>
                            <p>STAR USA MOVING LLC AND/OR ITS SUPPLIERS MAKE NO REPRESENTATIONS ABOUT THE SUITABILITY, RELIABILITY, AVAILABILITY, TIMELINESS, AND ACCURACY OF THE INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS CONTAINED ON THE SITE FOR ANY PURPOSE. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, ALL SUCH INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS ARE PROVIDED "AS IS" WITHOUT WARRANTY OR CONDITION OF ANY KIND. STAR USA MOVING LLC AND/OR ITS SUPPLIERS HEREBY DISCLAIM ALL WARRANTIES AND CONDITIONS WITH REGARD TO THIS INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS, INCLUDING ALL IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT.</p>
                            <p>TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL STAR USA MOVING LLC AND/OR ITS SUPPLIERS BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL DAMAGES OR ANY DAMAGES WHATSOEVER INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF USE, DATA OR PROFITS, ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE OR PERFORMANCE OF THE SITE, WITH THE DELAY OR INABILITY TO USE THE SITE OR RELATED SERVICES, THE PROVISION OF OR FAILURE TO PROVIDE SERVICES, OR FOR ANY INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS OBTAINED THROUGH THE SITE, OR OTHERWISE ARISING OUT OF THE USE OF THE SITE, WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, EVEN IF STAR USA MOVING LLC OR ANY OF ITS SUPPLIERS HAS BEEN ADVISED OF THE POSSIBILITY OF DAMAGES. BECAUSE SOME STATES/JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY TO YOU. IF YOU ARE DISSATISFIED WITH ANY PORTION OF THE SITE, OR WITH ANY OF THESE TERMS OF USE, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING THE SITE.</p>
                            <h2 className={'title mb-4'}>Termination/Access Restriction</h2>
                            <p>Star USA Moving reserves the right, in its sole discretion, to terminate your access to the Site and the related services or any portion thereof at any time, without notice. To the maximum extent permitted by law, this agreement is governed by the laws of the State of Georgia and you hereby consent to the exclusive jurisdiction and venue of courts in Georgia in all disputes arising out of or relating to the use of the Site. Use of the Site is unauthorized in any jurisdiction that does not give effect to all provisions of these Terms, including, without limitation, this section.</p>
                            <p>You agree that no joint venture, partnership, employment, or agency relationship exists between you and Star USA Moving as a result of this agreement or use of the Site. Star USA Moving's performance of this agreement is subject to existing laws and legal process, and nothing contained in this agreement is in derogation of Star USA Moving's right to comply with governmental, court and law enforcement requests or requirements relating to your use of the Site or information provided to or gathered by Star USA Moving with respect to such use. If any part of this agreement is determined to be invalid or unenforceable pursuant to applicable law including, but not limited to, the warranty disclaimers and liability limitations set forth above, then the invalid or unenforceable provision will be deemed superseded by a valid, enforceable provision that most closely matches the intent of the original provision and the remainder of the agreement shall continue in effect.</p>
                            <p>Unless otherwise specified herein, this agreement constitutes the entire agreement between the user and Star USA Moving with respect to the Site and it supersedes all prior or contemporaneous communications and proposals, whether electronic, oral or written, between the user and Star USA Moving with respect to the Site. A printed version of this agreement and of any notice given in electronic form shall be admissible in judicial or administrative proceedings based upon or relating to this agreement to the same extent and subject to the same conditions as other business documents and records originally generated and maintained in printed form. It is the express wish to the parties that this agreement and all related documents be written in English.</p>
                            <h2 className={'title mb-4'}>Changes to Terms</h2>
                            <p>Star USA Moving reserves the right, in its sole discretion, to change the Terms under which https://starusamoving.com is offered. The most current version of the Terms will supersede all previous versions. Star USA Moving encourages you to periodically review the Terms to stay informed of our updates.</p>
                            <h2 className={'title mb-4'}>Contact Information</h2>
                            <p>Star USA Moving welcomes your questions or comments regarding the Terms:</p>
                            <p>Star USA Moving LLC<br/>
                                5952 Peachtree Industrial Blvd # 15<br/>
                                Norcross, Georgia 30071</p>
                            <p>Email Address: sales@starusamoving.com<br/>
                                Phone Number: 678-462-8392<br/>
                                Effective as of April 03, 2024</p>
                        </div>
                    </div>
                </section>
            </main>
        );
    }
}